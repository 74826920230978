import {
  SUPER_ADMIN,
  UserRole,
  adminRoot,
  currentUser,
  themeColorStorageKey,
} from 'constants/defaultValues';
import { auth } from 'helpers/Firebase';
import { setCurrentUser } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { postData as postDataWithAuth } from 'views/app/fetch-services';
import { postData } from '../../views/app/fetch-services-without-token';
import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  AUTHENTICATE_USER,
} from '../contants';

import {
  AuthenticateUserError,
  forgotPasswordError,
  forgotPasswordSuccess,
  loginUserError,
  loginUserFailed,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
export function* watchAuthenticateUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(AUTHENTICATE_USER, AuthenticateUser);
}
// const loginWithEmailPasswordAsync = async (email, password) =>
//   // eslint-disable-next-line no-return-await
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);

const fetchAppModules = async () => {
  const result = await postDataWithAuth('permissions/getAppModules');
  if (result.success) {
    localStorage.setItem('appModules', JSON.stringify(result.data));
  }
};

const fetchActiveKeys = async (companyId, token) => {
  const result = await postDataWithAuth(
    'permissions/getActiveKeys',
    { company_id: companyId },
    token
  );

  if (result.success) {
    localStorage.setItem('activeSessionKeys', JSON.stringify(result.data));
  }
};

const checkLogin = async (email, password) => {
  // eslint-disable-next-line no-return-await
  const body = {
    email,
    password,
  };
  const loginUser = await postData('auth/login', body);
  if (!loginUser.message) {
    localStorage.setItem('loginUser', JSON.stringify(loginUser.user));
    localStorage.setItem('profileImage', loginUser.user.image);
    localStorage.setItem('sessionUserId', loginUser.user._id);
    localStorage.setItem('sessionUserName', loginUser.user.name);
    localStorage.setItem('sessionUserRole', loginUser.user.type);
    localStorage.setItem('sessionUserEmail', loginUser.user.email);
    localStorage.setItem('sessionUserPhone', loginUser.user.phone);
    localStorage.setItem('jws_token', loginUser.token);
    localStorage.setItem('reload', 'true');
    localStorage.setItem('defaultDashboard', loginUser.user.defaultDashboard);
    localStorage.setItem(
      'twoFactorAuth',
      loginUser.companyInfo.twoFactorAuthentication
    );

    if (loginUser.companyInfo.twoFactorAuthentication !== true) {
      await fetchAppModules();
      await fetchActiveKeys(loginUser?.user?.company_id, loginUser.token);
    }
  }
  if (loginUser.user.type === 'employee') {
    localStorage.setItem('employee_id', loginUser.user._id);
  }
  if (loginUser.user.type !== 'super_admin') {
    localStorage.setItem('company_id', loginUser.user.company_id);
  }

  return loginUser;
};
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;

  try {
    const loginUser = yield call(checkLogin, email, password);
    const twoFactorAuth = loginUser?.companyInfo?.twoFactorAuthentication;

    if (loginUser) {
      const role = UserRole[loginUser.user.type];
      const item = {
        ...currentUser,
        uid: loginUser.user.uid,
        role,
      };

      setCurrentUser(item);
      yield put(loginUserSuccess(item));

      const redirectUrl = localStorage.getItem('redirectUrl')
        ? JSON.parse(localStorage.getItem('redirectUrl'))
        : null;
      if (redirectUrl) {
        // Push the new location to history
        history.push({
          pathname: redirectUrl.pathname,
          search: redirectUrl.search,
          state: redirectUrl.state,
        });
      } else {
        const pagehistory = localStorage.getItem('pagehistory')
          ? JSON.parse(localStorage.getItem('pagehistory'))
          : null;

        const defaultDashboard = localStorage.getItem('defaultDashboard');
        const hasHistory =
          pagehistory &&
          Object.keys(pagehistory).includes(role.toString()) &&
          role !== SUPER_ADMIN;
        if (hasHistory) {
          history.push(pagehistory[role].pathname, pagehistory[role].state);
        } else if (role === SUPER_ADMIN) {
          history.push(`${adminRoot}/dashboard/${defaultDashboard}`, {
            skipHistory: role === SUPER_ADMIN,
          });
        } else if (twoFactorAuth) {
          history.push('/user/authentication', {
            skipHistory: role === SUPER_ADMIN,
          });
        } else {
          history.push(`${adminRoot}/dashboard/${defaultDashboard}`, {
            skipHistory: role === SUPER_ADMIN,
          });
        }
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserFailed());
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = {
        ...currentUser,
        uid: registerUser.user.uid,
        role: UserRole[registerUser.user.type],
      };

      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const currentTheme = localStorage.getItem(themeColorStorageKey);
  // const pagehistory = localStorage.getItem('pagehistory'); // remove pagehistory when logout

  localStorage.clear();
  if (currentTheme) localStorage.setItem(themeColorStorageKey, currentTheme);
  // if (pagehistory) localStorage.setItem('pagehistory', pagehistory); // remove pagehistory when logout
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
  window.location.reload();
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  const result = await postData('auth/resetLink', { email });
  return result;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.success) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (userId, resetPasswordCode, newPassword) => {
  const result = await postData('auth/changePassword', {
    id: userId,
    token: resetPasswordCode,
    password: newPassword,
  });
  return result;
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode, userId } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      userId,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordStatus?.success) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

const verifyQRCodeToken = async (payload) => {
  // eslint-disable-next-line no-return-await
  const { user } = payload;
  const result = await postData('auth/v2/verifyQRCodeToken', {
    secret: user.secret,
    token: user.code,
    userId: user._id,
  });
  // const defaultDashboard = localStorage.getItem('defaultDashboard');
  if (result.isValid) {
    localStorage.setItem('jws_token', result.token);

    await fetchAppModules();
    await fetchActiveKeys(result?.user?.company_id, result.token);

    // history.push(`${adminRoot}/dashboard/${defaultDashboard}`);
  }

  return result;
};

const verifyEmailOtpCode = async (payload) => {
  // eslint-disable-next-line no-return-await
  const { user } = payload;
  const result = await postData('auth/v2/verifyEmailOTP', {
    userId: user._id,
    otp: user.code,
  });
  // const defaultDashboard = localStorage.getItem('defaultDashboard');
  if (result.isValid) {
    localStorage.setItem('jws_token', result.token);

    await fetchAppModules();
    await fetchActiveKeys(result?.user?.company_id, result.token);

    // history.push(`${adminRoot}/dashboard/${defaultDashboard}`);
  }

  return result;
};

function* AuthenticateUser({ payload }) {
  const { history } = payload;
  const { secret } = payload.user;

  try {
    const result = secret
      ? yield call(verifyQRCodeToken, payload)
      : yield call(verifyEmailOtpCode, payload);

    if (result.isValid) {
      const role = UserRole[result.user.type];
      const item = {
        ...currentUser,
        uid: result.user.uid,
        role,
      };

      setCurrentUser(item);
      yield put(loginUserSuccess(item));

      const redirectUrl = localStorage.getItem('redirectUrl')
        ? JSON.parse(localStorage.getItem('redirectUrl'))
        : null;
      if (redirectUrl) {
        // Push the new location to history
        history.push({
          pathname: redirectUrl.pathname,
          search: redirectUrl.search,
          state: redirectUrl.state,
        });
      } else {
        const pagehistory = localStorage.getItem('pagehistory')
          ? JSON.parse(localStorage.getItem('pagehistory'))
          : null;

        const defaultDashboard = localStorage.getItem('defaultDashboard');
        const hasHistory =
          pagehistory &&
          Object.keys(pagehistory).includes(role.toString()) &&
          role !== SUPER_ADMIN;
        if (hasHistory) {
          history.push(pagehistory[role].pathname, pagehistory[role].state);
        } else {
          history.push(`${adminRoot}/dashboard/${defaultDashboard}`, {
            skipHistory: role === SUPER_ADMIN,
          });
        }
      }
    } else {
      yield put(AuthenticateUserError(result.message));
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserFailed());
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchAuthenticateUser),

    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
